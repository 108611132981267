import Grid from "@mui/material/Grid";
import moment from "moment";
import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  GetSubmissionFilters
} from "../../../Services/candidateService";
import getLoggedInUserRole from "../../../Shared/utils/getLoggedInUserRole";
import getUserDetails from "../../../Shared/utils/getUserDetails";
import { setSelectedIndex, setSelectedRow } from "../../../features/recruiterSubmission/activeRecruiterSubmissionRowSlice";
import { setFilterType, useGetSubmissionsMutation } from "../../../features/submission/submissionSlice";
import useApi from "../../../hooks/useApi";
import { UserAuth } from "../../../models/interfaces";
import {
  SubmissionFilter,
  submissionFilterStateDefault,
} from "../../../models/submission/submissionFilterState";
import { AppState } from "../../../store/AppState";
import { capitalizeFirstLetter } from "../../../utils/StringUtil";
import { getNevLink } from "../../../utils/getFileFromUrl";
import SuccessSnackBarPopup from "../../ClientTeam/components/Popup/successSnackBarPopup";
import SnackbarPopup from "../../Common/Popup/snackbar/SnackbarPopup";
import "../NewViewSubmission/styles.scss";
import SubmissionRow from "./SubmissionRow";
import SubmissionSearchBar from "./SubmissionSearchBar";
import { valueOrDefault } from "../../../utils/complexityUtils";
import AppBreadCrumb from "../../Common/AppBreadCrumb";
import { BreadCrumbItem } from "../../../models/AppModels";
import { PageName } from "../../../models/app/Page/PageModel";
import { setBreadCrumbsState } from "../../../features/Common/BreadcrumbsStateSlice";
import { GotoTop } from "../../ui/GotoTop";

export const setDurationCriteriaMsg = (duration:any,durationType:any,fromDate:any,toDate:any) => {
   
  let selectCriteriaMsg = `Submissions showing `;
  switch (duration) {
  
    case "1":
      selectCriteriaMsg += `for last 24 hours`;
      break;
    case "2":
      selectCriteriaMsg += `for last 48 hours`;
      break;
    case "7":
      selectCriteriaMsg += `for last 7 days`;
      break;
    case "30":
      selectCriteriaMsg += `for last 30 days`;
      break;
    case "60":
      selectCriteriaMsg += `for last 60 days`;
      break;
    case "90":
      selectCriteriaMsg += `for last 3 months`;
      break;
    case "120":
      selectCriteriaMsg += `for last 6 months`;
      break;
    case "2022":
      selectCriteriaMsg += `for year 2022`;
      break;
    case "2023":
      selectCriteriaMsg += `for year 2023`;
      break;
      case "0":
        selectCriteriaMsg = ``
        break;
    //if duration is empty then treating as custom date filter
    case "":
    
      if (durationType === "All") {
        selectCriteriaMsg = ``
      } else {
        selectCriteriaMsg += `from ${moment(fromDate?.toString()).format("LL")} to ${moment(toDate?.toString()).format("LL")}`;
      }
      break;
    default:
      selectCriteriaMsg = ``;
      break;
  }

return selectCriteriaMsg  
};
export default function Submissions() {
  const navigate = useNavigate()
  const location = useLocation()

  let type = ''
  if (location.pathname.endsWith("/submission/active")) {
    type = 'active'
  }else if(location.pathname.endsWith("/submission/archived")){
    type = 'archived'
  }  
  let getSubmissionFilters = useApi(() => GetSubmissionFilters(type||''));

  const dispatch = useDispatch<any>()
  let userRole = getLoggedInUserRole();
  let userDetails = getUserDetails()

  const [activeSubmissions, setActiveSubmissions] = React.useState<any>([]);
  const [submissionService, submissionResult] = useGetSubmissionsMutation();
  const isMounted = useRef(false);
  const [totalRecords, setTotalRecords] = React.useState<number>(0);
  const [loading, setLoading] = React.useState(true);
  const [selectedDurationMessage, setSelectedDurationMessage] = React.useState('');

  const [candidateRejectStatus, setcandidateRejectStatus] = React.useState({
    isSuccess: false,
    message: "",
    unique: Date.now(),
  });

  const [updateCandidateStatus, setUpdateCandidateStatus] = React.useState({
    submittedToClient:{
      isSuccess: false,
      message: "Candidate has been approved successfully!",
    },    
    unique: Date.now(),
  });

  const { filterType } = useSelector(
    (state: AppState) => state.submissionReducer
  );
  const {
    searchKeywords,
    candidateGuids,
    clientNames,
    recruiterFilter,
    statusIds,
    durationFilter
  } = useSelector((state: AppState) => state.submissionReducer[filterType]);
  const [searchParams] = useSearchParams();

  let recruiterGuid = searchParams.get("recruiterGuid") === null ? null : searchParams.get("recruiterGuid");
  const [paging, setPaging] = React.useState<SubmissionFilter>({
    ...submissionFilterStateDefault,
    searchKeywords: searchKeywords,
    recruiterGuid : extractRecruiterGuid(),
    statusIds : statusIds,
    durationFilter:durationFilter
  });

  function extractRecruiterGuid() {
    if (userRole === UserAuth.Recruiter) {
      return userDetails?.id || '';
    }
    else if (recruiterGuid != null) {
      return recruiterGuid;
    }
    return "";
  }

  const onUpdateStatusSuccessClose = (message:string) => {
    setUpdateCandidateStatus({...updateCandidateStatus, submittedToClient : {
      isSuccess: true, 
      message: message,
     },
     unique: Date.now() })
    
     setPaging({
      ...paging, 
       pageIndex: 1
     })

     dispatch(setSelectedRow(null))
     dispatch(setSelectedIndex(null))
  }

  const onRejectSuccess = () => {
    setcandidateRejectStatus({ isSuccess: true, message: "Submission has been rejected successfully", unique: Date.now() })
    setPaging({
      ...paging, 
       pageIndex: 1
     })
     
     dispatch(setSelectedRow(null))
     dispatch(setSelectedIndex(null))

  }
  const onRejectSuccessClose = () => {
    setcandidateRejectStatus({ isSuccess: false, message: "", unique: Date.now() })
  }
  const onCommentAddedSuccess = () => {
    setPaging({
      ...paging, 
       pageIndex: 1
     })

     dispatch(setSelectedRow(null))
     dispatch(setSelectedIndex(null))
    setcandidateRejectStatus({ isSuccess: true, message: "Comment Added Successfully", unique: Date.now() });
    
  }
  const onRTRBypassSuccess = () => {
    setPaging({
     ...paging, 
      pageIndex: 1
    })

    dispatch(setSelectedRow(null))
    dispatch(setSelectedIndex(null))
  }
  const resetPayloadHandlerForUpdate = (jobId:any, id:any) => {

    setPaging({
      ...paging, 
       pageIndex: 1
     })

    navigate(getNevLink(`/submitresume/${jobId}/submission${type}/?id=${id}`));
  }
  React.useEffect(()=>{

    return () => {
      dispatch(setBreadCrumbsState({...BreadcrumbState, navigateFromPage:PageName.DASHBOARD }));
      }
  },[])
  
  React.useEffect(() => {
    getSubmissionFilters?.request();
    return () => {
      // reset when switch active to archive or vice versa
      setTotalRecords(0)
      setActiveSubmissions([]);
      }
  }, [type]);

  React.useLayoutEffect(() => {
    getSubmissionFilters.clear()
    dispatch(setFilterType(type?.toLowerCase() === "active" ? "filter" : "pendingFilter"));
  }, [type]);

  React.useLayoutEffect(() => {
     
    if (isMounted.current) {
      setPaging({
        ...paging,
        pageIndex: 1,
        searchKeywords: searchKeywords,
        candidateGuids: candidateGuids,
        clientNames: clientNames,
        recruiterFilter: recruiterFilter,
        statusIds: statusIds,
        durationFilter : durationFilter,

      });
    } else {
      isMounted.current = true;
    }
  }, [searchKeywords, candidateGuids, clientNames, recruiterFilter, statusIds,durationFilter,type]);

  React.useEffect(() => {
    const { duration,durationType, fromDate,toDate } = durationFilter;

    const selectCriteriaMsg = setDurationCriteriaMsg(duration, durationType, fromDate, toDate);

    
    const shouldCallService = () => {
      const { durationType, fromDate } = durationFilter || {};
      if (durationType === "Custom" && fromDate === "") {
        return false; // do not call the service
      }
      setSelectedDurationMessage(selectCriteriaMsg)

      return true; // call the service
    };
    if (
      getSubmissionFilters.data &&
      getSubmissionFilters.data?.entityList
     && shouldCallService())
       {
        GetSubmissions();
    }
  }, [paging, getSubmissionFilters?.data]);

  React.useEffect(() => {
    if (paging.pageIndex === 1) {
      dispatch(setSelectedRow(null))
      dispatch(setSelectedIndex(null))
    } 
    setActiveSubmissions(submissionResult.data?.entityList ?? []); 
    if(submissionResult.data?.totalRecords !== undefined){
      setTotalRecords(submissionResult.data?.totalRecords || 0)
    }
    
  }, [submissionResult.data?.entityList]);

const GetSubmissions = () =>{
  setLoading(true);
  let requestData = paging;
  if (requestData.statusIds.length == 0) {
    let statusIds = getSubmissionFilters?.data?.entityList;
    requestData.statusIds = statusIds?.map((x: any) => x.id);
    submissionService(requestData).finally(()=> setLoading(false));
  }
  else
  {
    submissionService(paging).finally(()=> setLoading(false));;
  }

}

  const _handlePaginationIndex = (index:number) => {
    dispatch(
      setPaging({
        ...paging,
        pageIndex: index,
      })
    );
    
  };

  const BreadcrumbState = useSelector((state:any) => state.BreadcrumbsState);
  const DefaultBreadCrumbs: BreadCrumbItem[] = [
    { isLeaf: false, sequence: 1, title: "Dashboard", to: getNevLink('/dashboard') },
    { isLeaf: true, sequence: 2, title: `${capitalizeFirstLetter(type)} Submissions`, leafClasses: " v2LastLeft"},
  ];

  const fromPageAdminRecruiterListBreadCrumbs: BreadCrumbItem[] = [
    { isLeaf: false, sequence: 1, title: "Dashboard", to: getNevLink('/dashboard') },
    { isLeaf: false, sequence: 2, title: "Recruiter", to: getNevLink('/recruiters') },
    { isLeaf: true, sequence: 3, title: `${capitalizeFirstLetter(type)} Submissions`, leafClasses: " v2LastLeft"},
  ];
  return (
    <>
     <Grid container>
        <Grid xs={12}>
          <Grid container>
            <Grid xs={12} >
              <AppBreadCrumb
                classes="custom-appbreadcrumb"
                BreadCrumbItems={
                  BreadcrumbState?.navigateFromPage === PageName.ADMINSUBMISSIONACTIVE 
                  ? fromPageAdminRecruiterListBreadCrumbs 
                  : DefaultBreadCrumbs
                }
              />
            </Grid>
            <Grid
              className="custom-margin-searchbar"
              item
              xs={12}
              data-testid="client-searchbar"
            >
              <SubmissionSearchBar
                durationMessage={selectedDurationMessage}
                type={type || ''}
                totalRecords={totalRecords}
                submissionFilters={getSubmissionFilters?.data?.entityList}
              />
            </Grid>
          </Grid>
          {candidateRejectStatus.isSuccess && (
              <SuccessSnackBarPopup
                open={candidateRejectStatus?.isSuccess}
                message={candidateRejectStatus.message}
                onClose={onRejectSuccessClose}
              />
            )}
          <Grid container spacing={2}>
            <Grid mt={2} item xs={12} sm={12} md={12} lg={12} xl={12}>
              <SubmissionRow
                key={candidateRejectStatus.unique}
                candidates={activeSubmissions}
                totalRecordCount={totalRecords}
                handlePaginationIndex={_handlePaginationIndex}
                filterState={paging}
                loading={loading}
                type={valueOrDefault(type, "")}
                onRejectSuccess={onRejectSuccess}
                onCommentAddedSuccess={onCommentAddedSuccess}
                onRTRBypassSuccess={onRTRBypassSuccess}
                resetPayloadHandlerForUpdate={resetPayloadHandlerForUpdate}
                onUpdateStatusSuccessClose={onUpdateStatusSuccessClose}
                userRole={userRole}
              />
            </Grid>
          </Grid>

          <SnackbarPopup 
            open={updateCandidateStatus.submittedToClient.isSuccess}
            message={updateCandidateStatus.submittedToClient.message}
            onClose={()=>{ 
                  setUpdateCandidateStatus({
                  ...updateCandidateStatus,
                  submittedToClient : {
                    ...updateCandidateStatus.submittedToClient,
                    isSuccess:false,
                
                  }
                })
              }
            }
          />
        </Grid>
      </Grid>
      <GotoTop/>
    </>
  );
}
