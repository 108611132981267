import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { Button, Typography } from "@mui/material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import DonutLargeOutlinedIcon from "@mui/icons-material/DonutLargeOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import { Searchbar } from "../../../ui/Searchbar";
import { useNavigate } from "react-router-dom";
import { getNevLink } from "../../../../utils/getFileFromUrl";
import RecruiterListFilters from "./RecruiterListFilters";
import { RecruiterListRequestPayload } from "../../../../models/Recruiter/RecruiterListModel";
import RecruiterStatusFilter from "./RecruiterStatusFilter";
import { GetRecruiterStatus } from "../../../../Services/recruiterService";
import IncompleteSignup from "../../IncompleteSignUp";
import formatAmount from "../../../../utils/formatAmount";
import { ModuleComponent } from "../../../Common/Permission/ModuleComponent";
import { PermissionsEnums } from "../../../../data/permission";
import SnackbarPopup from "../../../Common/Popup/snackbar/SnackbarPopup";

interface Recruiter {
  id: number;
  name: string;
}

interface RecruiterListTableToolbarProps {
    totalRecruiterCount:number;
    RecruiterListState: RecruiterListRequestPayload;
    handleSearchbar: (searchText: string) => void;
    handleClearGlobalAll: () => void;
    handleOnStatusChange :any;
    handleOnLocationChange:any;
    handleOnIndustryChange:any;
    handleOnDomainChange:any;
    handleOnSkillChange:any;
    selectedRecruiterStatuses:[];
    selectedRecruiterLocation:[];
    selectedRecruiterIndustries:[];
    selectedRecruiterDomains:[];
    selectedRecruiterSkills:[];
    handleContractStatusChange:(flag:boolean) => void;
  }

const RecruiterListTableToolbar: React.FC<RecruiterListTableToolbarProps> = ({
    totalRecruiterCount,
    RecruiterListState,
    handleSearchbar,
    handleClearGlobalAll,
    handleOnStatusChange,
    handleOnLocationChange,
    handleOnIndustryChange,
    handleOnDomainChange,
    handleOnSkillChange,
    selectedRecruiterStatuses,
    selectedRecruiterLocation,
    selectedRecruiterIndustries,
    selectedRecruiterDomains,
    selectedRecruiterSkills,
    handleContractStatusChange
  }) => {
  const navigate = useNavigate();
  const filterRef = React.useRef<any>(null);
  const statusFilterRef = React.useRef<any>(null);

  // Component states
  const [anchorFilter, setAnchorFilter] = useState<any>(false);
  const [statusAnchor, setStatusAnchor] = useState<any>(false);
  const [searchKeyword,setSearchKeyword] = useState<string>(RecruiterListState?.searchKeywords);
  const [recruiterStatusesList, setRecruiterStatusesList] = useState<any>([]);
  const [isIncompleteSignupOpen, setIsIncompleteSignupOpen] = useState<boolean>(false);
  const [recruiterDeleteStatus, setRecruiterDeleteStatus] = React.useState<any>({ 
    isSuccess: null, 
    message: '', 
    unique: Date.now() });

// UseEffect calls

useEffect(()=>{
    _GetRecruiterStatusesList();
},[])

// Check is any filter is apply
const _hasAnyFilterApply = () => {
    return (
        (RecruiterListState?.searchKeywords !=="") ||
        (RecruiterListState?.status?.length && RecruiterListState?.status?.length > 0) ||
        (RecruiterListState.filterByMasterData?.locationFilterInputs && RecruiterListState.filterByMasterData?.locationFilterInputs?.length > 0) ||
        (RecruiterListState.filterByMasterData?.industryFilterInputs && RecruiterListState.filterByMasterData?.industryFilterInputs?.length > 0) ||
        (RecruiterListState.filterByMasterData?.domainFilterInputs && RecruiterListState.filterByMasterData?.domainFilterInputs?.length > 0) ||
        (RecruiterListState.filterByMasterData?.skillsFilterInputs && RecruiterListState.filterByMasterData?.skillsFilterInputs?.length > 0) ||
        RecruiterListState.ShowRecruitersWithPendingContract ||
        (searchKeyword && searchKeyword !== "")
    );
  };

  // Handle incomplete signup popup
  const _handleIncompleteClick = ()=>{
    setIsIncompleteSignupOpen(true)
  }
  
  const _handleCloseIncompletePopup = () =>{
    setIsIncompleteSignupOpen(false)
  }

  // On Delete Incomplete recruiter
  const onRecruiterDeleteSuccess = () => {
    setRecruiterDeleteStatus({ 
      isSuccess: true, 
      message: "Recruiter deleted successfully", 
      unique: Date.now() }) 
  }

// clear All
const _handleClearAll = () =>{
    setSearchKeyword("")
    filterRef.current?.handleClearAll();
    statusFilterRef.current?.handleClearAll();
    handleClearGlobalAll();
  }


  // Status filter action function

  const _handleStatusClick = (event: any)=>{
    setStatusAnchor(statusAnchor ? null : event.currentTarget);
  }


  const _handleFilterClick = (event: any)=>{
    setAnchorFilter(anchorFilter ? null : event.currentTarget);
  }

  const _handleSearchKeywords = ()=>{
    handleSearchbar(searchKeyword)
  }

  const _GetRecruiterStatusesList = ()=>{
    GetRecruiterStatus()
    ?.then((response) => {
        if (response?.data?.message?.appStatusCode === "RC_RSF") {
            setRecruiterStatusesList(response?.data?.entityList);
        } else {
            setRecruiterStatusesList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
  <>
    <Grid container spacing={0}>
      {/* Row 1 */}
      <Grid item xs={12}>
        <Grid container spacing={2} justifyContent={{ sm: "flex-start", md: "space-between" }}>
          <Grid item>
            <Typography className="listing-total-heading">Total Recruiters ({formatAmount(totalRecruiterCount)})</Typography>
          </Grid>
          <Grid item>
            <Grid
              container
              justifyContent={{ sm: "flex-start", md: "flex-end" }}
            >
              <Grid
                item
                style={{ position: "relative" }}
              >
                <Searchbar
                  classes="ClientListingSearchbarContainer searchBarResWidth"
                  isNewSeachBar={true}
                  width={"300px"}
                  height="1.7rem"
                  placeHolderText={"Search"}
                  txtFontSize={"14px"}
                  marginBottom="16px"
                  value={searchKeyword}
                  onChange={(e:any) => {setSearchKeyword(e.target.value)}}
                  onEnter={_handleSearchKeywords}
                  onIconClick={_handleSearchKeywords}
                />
              </Grid>
              <Grid
                item
              >
                <Button
                    data-testid="statusbutton"
                  variant="text"
                  component="div"
                  className="v2-base-btn cust-btn-bgClr searchbarRow-ht-btn toolbarActionBtn"
                  startIcon={<DonutLargeOutlinedIcon className="statusIcon"/>}
                  onClick={_handleStatusClick}
                >
                  Status
                </Button>
                <Button
                  variant="text"
                  component="div"
                  size="large"
                  className="v2-base-btn cust-btn-bgClr searchbarRow-ht-btn toolbarActionBtn"
                  startIcon={<FilterAltOutlinedIcon />}
                  onClick={_handleFilterClick}
                  data-testid="multifilter-button"
                >
                  Filter
                </Button>
                <Button
                  variant="contained"
                  className="custPrimary searchbarRow-ht-btn toolbarActionBtn"
                  onClick={_handleIncompleteClick}
                >
                  Incomplete Signup
                </Button>

                <ModuleComponent moduleId={PermissionsEnums.Recruiter_Add}>
                  <Button
                    variant="contained"
                    startIcon={<AddOutlinedIcon />}
                    className="custPrimary searchbarRow-ht-btn toolbarActionBtn"
                    onClick={() => {
                      navigate(getNevLink("/recruiter/add"));
                    }}
                  >
                    Add Recruiter
                  </Button>
                </ModuleComponent>
                <Button
                  variant="text"
                  component="div"
                  size="large"
                  className={`toolbarActionBtn ${
                    _hasAnyFilterApply()
                      ? "v2-base-btn"
                      : "disable-v2-base-btn"
                  } cust-btn-bgClr searchbarRow-ht-btn clear-cust-wrap`}
                  startIcon={<ClearIcon />}
                  onClick={_handleClearAll}
                  disabled={!_hasAnyFilterApply()}
                  data-testid="clear-button"
                >
                  Clear
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>        
      </Grid>
      <Grid item xs={12}>
        {isIncompleteSignupOpen && 
          <IncompleteSignup
          handleClose1={_handleCloseIncompletePopup}
          onRecruiterDeleteSuccess={onRecruiterDeleteSuccess}
            />}

          {recruiterDeleteStatus.isSuccess && (
            <SnackbarPopup
            open={recruiterDeleteStatus.isSuccess}
            message={recruiterDeleteStatus.message}
            onClose={() => setRecruiterDeleteStatus(false)}
          />
          )}
        </Grid>
    </Grid>

    <RecruiterListFilters
        ref={filterRef}
        anchorFilter={anchorFilter}
        onClose={_handleFilterClick}        
        onChange={handleOnLocationChange}
        onIndustryChange={handleOnIndustryChange}
        handleDomainChange={handleOnDomainChange}
        handleSkillChange={handleOnSkillChange}
        selectedIndustries={selectedRecruiterIndustries}
        selectedLocations={selectedRecruiterLocation}
        selectedDomains={selectedRecruiterDomains}
        selectedSkills={selectedRecruiterSkills}
    />

    <RecruiterStatusFilter
        ref={statusFilterRef}
        anchorEl={statusAnchor}
        onClose={_handleStatusClick}
        statues={recruiterStatusesList}
        selectedStatuses={selectedRecruiterStatuses}
        onStatusChange={handleOnStatusChange}
        RecruiterListState={RecruiterListState}
        handleContractStatusChange={handleContractStatusChange}
    />
    </>
  );
};

export default RecruiterListTableToolbar;
