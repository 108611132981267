import ClearIcon from "@mui/icons-material/Clear";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Button,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Radio from "@mui/material/Radio";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearAllDateFilter,
  clearAllFilter,
  handleDateRangeFilter,
  handleDurationFilter,
  handleStatusFilter,
  handleSubmissionSearchKeyword,
  initialState
} from "../../../features/submission/submissionSlice";
import { AppState } from "../../../store/AppState";
import { capitalizeFirstLetter } from "../../../utils/StringUtil";
import { isDateFormat } from "../../../utils/dateUtils";
import { ClientFilterRef } from "../../Client/Listing/ClientFilter";
import BasicDatePicker from "../../ui/DateRange";
import { Searchbar } from "../../ui/Searchbar";
import SubmissionFilter from "./SubmissionFilter";
import "./submissionrow.scss";
import { ternary } from "../../../utils/complexityUtils";

const StyledButton = styled(Button)(() => ({
  borderRadius: '5px', // Rounded corners
  borderColor: '#9b9b9b',
  justifyContent: 'center',
  ":hover": {
    color: "black !important",
    backgroundColor: "white !important",
  },
}));

interface SubmissionSearchBarProps {
  totalRecords: number; // Define the type of the totalRecords prop
  submissionFilters: any[];
  type: string;
  durationMessage: string;
}

const SubmissionSearchBar: React.FC<SubmissionSearchBarProps> = ({
  totalRecords,
  submissionFilters,
  type,
  durationMessage,
}) => {
  const dispatch = useDispatch<any>();
  const filterRef = React.useRef<ClientFilterRef>(null);
  const { filterType } = useSelector(
    (state: AppState) => state.submissionReducer
  );

  const { searchKeywords, statusIds, durationFilter } = useSelector(
    (state: AppState) => state.submissionReducer[filterType]
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [expandStatus, setexpandStatus] = React.useState(false);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const searchBarWidth = isSmallScreen ? "250px" : "343px";
  const handleFilterClick = (event: any) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleDurationClick = (event: any) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleCustomClick = () => {
    dispatch(
      handleDurationFilter({
        durationValue: "",
        durationType: "Custom",
      })
    );
  };
  const handleListItemClick = (duration: any, durationType: any) => {
    dispatch(
      handleDurationFilter({
        durationValue: duration,
        durationType: durationType,
      })
    );
  };
  const handleClose = () => {
    setAnchorEl2(null);
  };
  const open2 = Boolean(anchorEl2);
  const [dateErrors, setDateErrors] = React.useState({ from: "", to: "" });
  const [datefilter, setDatefilter] = React.useState({
    from: null,
    to: null,
  });
  const handleDateChange = (e: any, name: any) => {
    if (e === null) {
      setDatefilter({ ...datefilter, [name]: null });
      return;
    }
    const dateObj = new Date(e);
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getDate()).padStart(2, "0");
    const year = dateObj.getFullYear();
    const formattedDate = `${month}/${day}/${year}`;
    const localTime = formattedDate;
    setDatefilter({ ...datefilter, [name]: localTime });
  };
  const submissionFilterState = useSelector(
    (state: any) => state.submissionReducer[filterType]
  ); // Update the slice name if it's different in your root reducer

  const formattedText = `${capitalizeFirstLetter(type)} Submissions ${
    ternary(totalRecords !== undefined && totalRecords !== 0 , `(${totalRecords})` , "(0)")
  }`;
  const haveAnyFilter = () => {
    if (
      JSON.stringify(submissionFilterState) !=
      JSON.stringify(initialState[filterType])
    )
      return true;
    else return false;
  };
  React.useEffect(() => {
    handleDateFilterValidation();
  }, [datefilter]);
  const handleDateFilterValidation = () => {
    if (!datefilter.from && !datefilter.to) {
      return;
    }

    if (datefilter.from === null || datefilter.from === undefined) {
      setDateErrors((prevErrors) => ({
        ...prevErrors,
        from: "",
      }));
    }

    if (datefilter.to === null || datefilter.to === undefined) {
      setDateErrors((prevErrors) => ({
        ...prevErrors,
        to: "",
      }));
    }
    let fromDate = new Date(datefilter.from);
    let toDate = new Date(datefilter.to);

    let fromDateOrderError = isInvalidDateOrder(datefilter, fromDate, toDate)
      ? "From date cannot be greater than To date"
      : "";

    let toDateOrderError = isInvalidDateOrder(datefilter, fromDate, toDate)
      ? "To date cannot be less than From date"
      : "";

    setDateErrors((prevErrors) => ({
      ...prevErrors,
      from: !isDateFormat(datefilter.from)
        ? "From date format is invalid"
        : fromDateOrderError,
      to: !isDateFormat(datefilter.to)
        ? "To date format is invalid"
        : toDateOrderError,
    }));
  };

  function isInvalidDateOrder(datefilter: any, fromDate: Date, toDate: Date) {
    return (datefilter.to !== null && datefilter.fromDate !== null)
    ? toDate < fromDate 
    : false;
  }


  const handleApplyDateChange = () => {
    dispatch(
      handleDateRangeFilter({
        fromDate: datefilter.from,
        toDate: datefilter.to,
      })
    );
    setAnchorEl2(null);
  };
  const clearAllHandler = () => {
    setDateErrors({ from: "", to: "" });
    setAnchorEl2(null);
    setDatefilter({ from: null, to: null });
    dispatch(clearAllDateFilter());
  };

  let statusesData = submissionFilters;
  let firstFive = statusesData?.slice(0, 6);

  // Extract the rest of the elements
  let restStatus = statusesData?.slice(6);

  return (
    <>
      <Grid
        container
        spacing={2}
        justifyContent={{ sm: "flex-start", md: "space-between" }}
      >
        
        <Grid item mt={2} xs={12} sm={12} md={4} lg={3.6}>
          <Typography className="listing-total-heading">
            {formattedText}
          </Typography>
        </Grid>
        <Grid item mt={2} xs={12} sm={12} md={8} lg={8.4} className="submissionListSearchBar">
          <Grid container justifyContent={{ sm: "flex-end", md: "flex-end" }}>
            <Grid item>
            <Searchbar
                key={2}
                classes="ClientListingSearchbarContainer"
                isNewSeachBar={true}
                width={searchBarWidth}
                height="1.7rem"
                placeHolderText={"Search"}
                txtFontSize={"14px"}
                value={searchKeywords}
                marginBottom="8px"
                onChange={(e: any) => {
                  dispatch(handleSubmissionSearchKeyword(e.target.value));
                }}
                onEnter={(e: any) =>
                  dispatch(handleSubmissionSearchKeyword(e.target.value))
                }
                onIconClick={(e: any) =>
                  dispatch(handleSubmissionSearchKeyword(e.target.value))
                }
              />
            </Grid>
            <Grid item>
            <Button
                variant="text"
                component="div"
                size="large"
                className="custom-Btn-fontsizes cust-btn-bgClr searchbarRow-ht-btn toolbarActionBtn roboto-btn"
                startIcon={<FilterAltOutlinedIcon />}
                onClick={handleFilterClick}
                data-testid="filter-button"
              >
                Filter
              </Button>
              <Button
                variant="contained"
                className="duration-filter searchbarRow-ht-btn toolbarActionBtn roboto-btn"
                onClick={handleDurationClick}
              >
                Select Duration
              </Button>
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl2}
                open={open2}
                className={"custom-menu-bar roboto-btn"}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    width: 400,
                  },
                }}
              >
                <Grid container spacing={3} alignItems="center">
                  <Grid item xs={8}>
                    <Typography ml={4} className="select_duration">
                      Select Duration
                    </Typography>
                  </Grid>
                  <Grid item xs={4} style={{ textAlign: "right" }}>
                    <Button
                      className="select_duration"
                      color="primary"
                      onClick={clearAllHandler}
                      style={{ textTransform: "none" }}
                    >
                      Clear All
                    </Button>
                  </Grid>
                </Grid>
                <hr />

                <RadioGroup
                  defaultValue="30"
                  aria-labelledby="demo-customized-radios"
                  name="customized-radios"
                >
                  <MenuList sx={{ padding: "5px" }}>
                    {durationList &&
                      durationList?.map((dur) => (
                        <MenuItem
                          key={dur.value}
                          onClick={(e) => {
                            handleListItemClick(dur.value, dur.type);
                          }}
                          sx={{
                            "&:hover": {
                              bgcolor: "transparent",
                              color: "black", // Set the text color to black on hover
                              "& .MuiRadio-root": { color: "white" }, // Set the Radio component's color to black on hover
                              "& .MuiListItemIcon-root": {
                                visibility: "visible",
                              }, // Make the icon visible on hover
                            },
                          }}
                        >
                          <ListItemIcon>
                            <Radio
                              sx={{
                                padding: "0px",
                              }}
                              checked={
                                durationFilter?.duration === dur?.value &&
                                durationFilter?.durationType !== "Custom"
                              }
                              value={dur.value}
                            />
                          </ListItemIcon>
                          <ListItemText>
                            <Typography className="select_duration">
                              {dur.label}
                            </Typography>
                          </ListItemText>
                        </MenuItem>
                      ))}
                    <MenuItem
                      key={"Custom"}
                      onClick={(e) => {
                        handleCustomClick();
                      }}
                      sx={{
                        "&:hover": {
                          bgcolor: "transparent",
                          color: "black", // Set the text color to black on hover
                          "& .MuiRadio-root": { color: "white" }, // Set the Radio component's color to black on hover
                          "& .MuiListItemIcon-root": {
                            visibility: "visible",
                          }, // Make the icon visible on hover
                        },
                      }}
                    >
                      <ListItemIcon>
                        <Radio
                          sx={{
                            padding: "0px",
                          }}
                          checked={
                            durationFilter?.duration === "" &&
                            durationFilter?.durationType === "Custom"
                          }
                          value={""}
                        />
                      </ListItemIcon>
                      <ListItemText>
                        {" "}
                        <Typography className="select_duration">
                          {"Custom Date"}
                        </Typography>
                      </ListItemText>
                    </MenuItem>
                  </MenuList>
                  {durationFilter?.durationType === "Custom" && (
                    <Grid container pl={2} spacing={0} direction="row">
                      <Grid item xs={4} md={4}>
                        <BasicDatePicker
                          label={"From Date"}
                          disableFuture={true}
                          value={datefilter.from}
                          name="from"
                          onChange={(e: any) => handleDateChange(e, "from")}
                          errorMessage={dateErrors.from}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        md={2}
                        style={{
                          paddingLeft: "29px",
                          paddingTop: "7px",
                          fontSize: "40px",
                        }}
                      >
                        -
                      </Grid>
                      <Grid item xs={4} md={4}>
                        <BasicDatePicker
                          label={"To Date"}
                          disableFuture={true}
                          value={datefilter.to}
                          name="to"
                          onChange={(e) => handleDateChange(e, "to")}
                          errorMessage={dateErrors.to}
                        />
                      </Grid>
                      <Grid item xs={8} md={8}>
                        <Grid container>
                          <Grid item xs={4} md={4}>
                            <Button
                              variant="contained"
                              onClick={() => {
                                handleApplyDateChange();
                              }}
                              disabled={
                                dateErrors.from !== "" ||
                                dateErrors.to !== "" ||
                                datefilter.from === null ||
                                datefilter.from === "" ||
                                datefilter.to === "" ||
                                datefilter.to === null
                              }
                            >
                              Apply
                            </Button>
                          </Grid>
                          <Grid item xs={4} md={4}>
                            <Button
                              variant="outlined"
                              onClick={(e) => clearAllHandler()}
                              data-testid="custom-date-cancel"
                            >
                              Cancel{" "}
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </RadioGroup>
              </Menu>

              <Button
                variant="text"
                size="small"
                onClick={() => {
                  filterRef?.current?.handleClearAll();
                  dispatch(clearAllFilter())
                }
              }
                className={`toolbarActionBtn ${
                  haveAnyFilter()
                    ? "custom-Btn-fontsizes"
                    : "disable-custom-Btn-fontsizes"
                } cust-btn-bgClr searchbarRow-ht-btn clear-cust-wrap`}
                data-testid="clear-button"
                disabled={!haveAnyFilter()}
              >
                <ClearIcon className="closeIcon" />
                Clear
              </Button>
              <Typography               
                ml={1}
                color="primary"
              >
                {durationMessage}
              </Typography>
            </Grid>
          </Grid>
             
              
              
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item pb={2} mt={-0.8} >
              <span className="select_dom">Status:</span>
              {firstFive?.map((x: any) => {
                return (
                  <FormControlLabel
                    key={x?.id}
                    control={
                      <Checkbox
                        onChange={() => {
                          dispatch(handleStatusFilter(x.id));
                        }}
                        name={x?.id}
                        size="small"
                        id={`checkBox-${x.id}`}
                        data-testid={`checkBox-${x.id}`}
                        checked={statusIds?.includes(x.id)}
                      />
                    }
                    label={
                      <span className="status-checkbox">{x.statusName}</span>
                    }
                  />
                );
              })}

              {expandStatus &&
                restStatus?.map((x: any) => {
                  return (
                    <FormControlLabel
                      key={x?.id}
                      control={
                        <Checkbox
                          onChange={() => {
                            dispatch(handleStatusFilter(x.id));
                          }}
                          name={x?.id}
                          size="small"
                          id={`checkBox-${x.id}`}
                          data-testid={`checkBox-${x.id}`}
                          checked={statusIds.includes(x.id)}
                        />
                      }
                      label={
                        <span className="status-checkbox">{x.statusName}</span>
                      }
                    />
                  );
                })}

              {/* collapse in/ out bug */}
              {statusesData?.length > 4 && (
                <StyledButton
                  size="small"
                  style={{
                    maxWidth: "30px",
                    minWidth: "30px",
                    marginRight: "16px",
                  }}
                  variant="outlined"
                  onClick={() => setexpandStatus(!expandStatus)}
                  data-testid={"btn-expandstatus"}
                >
                  {expandStatus ? (
                    <KeyboardArrowUpIcon sx={{ color: "black" }} />
                  ) : (
                    <KeyboardArrowDownIcon sx={{ color: "black" }} />
                  )}
                </StyledButton>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {statusesData && (
        <SubmissionFilter
          ref={filterRef}
          anchorEl={anchorEl}
          onClose={handleFilterClick}
          statusData={statusesData}
        />
      )}
    </>
  );
};
export default SubmissionSearchBar;

const durationList = [
  { label: "All", value: "0", type: "All" },
  { label: "Last 24 Hours", value: "1", type: "Days" },
  { label: "Last 48 Hours", value: "2", type: "Days" },
  { label: "Last 7 Days", value: "7", type: "Days" },
  { label: "Last 30 Days", value: "30", type: "Days" },
  { label: "Last 60 Days", value: "60", type: "Days" },
  { label: "Last 3 Months", value: "90", type: "Days" },
  { label: "Last 6 Months", value: "120", type: "Days" },
  { label: "2022", value: "2022", type: "Year" },
  { label: "2023", value: "2023", type: "Year" },
];
