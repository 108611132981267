import React, { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import RecruiterListTableToolbar from "./components/RecruiterListTableToolbar";
import RecruiterListTable from "./components/RecruiterListTable";
import { GetRecruiters } from "../../../Services/recruiterService";
import { useDispatch, useSelector } from "react-redux";
import './Recruiter.scss';
import { resetRecruiterListState, setRecruiterListState } from "../../../features/Recruiter/RecruiterListStateSlice";
import { Industry, SortType } from "../../../models/app/AppModels";
import getAddressForAutoComplete from "../../../utils/getAddressForAutoComplete";
import { GotoTop } from "../../ui/GotoTop";
import { useSearchParams } from "react-router-dom";
import { ternary, valueOrDefault } from "../../../utils/complexityUtils";
import { AxiosResponse } from "axios";
const RecruiterList: React.FC = () => {

  // Initialisation
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const recruiterPayloadRef = useRef<any>();
  const statusParam = searchParams.get("status");
  //acces the redux toolkit
  const RecruiterListState = useSelector((state:any)=>state.recruiterListState);
  // Component state
  const [recruiterDataList, setRecruiterDataList] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isDispatchState, setIsDispatchedState] = useState<boolean>(false);
  const [totalRecruiterCount, setTotalRecruiterCount] = useState<number>(0); 
  
  useEffect(() => {

    return(()=>{
      dispatch(setRecruiterListState({
        ...recruiterPayloadRef.current,
        pageIndex: 1,
        }))
    })
        
  }, []);

  // UseEffect call
  useEffect(()=>{
    if(statusParam)
    _handleOnStatusChange([statusParam]);
  },[statusParam]);

  useEffect(()=>{
    if(RecruiterListState.pageIndex > 1){
      _GetRecruiterList();
    }
  },[
    RecruiterListState.pageIndex,
  ])

  useEffect(()=>{
    if(isDispatchState){
      _GetRecruiterList();
    }
  },[
    isDispatchState
  ])

  // Reset or clear All Global states
  const _handleClearGlobalAll = () =>{
    dispatch(resetRecruiterListState())
    setIsDispatchedState(true);
  }

  const _handleSearchKeywords = (searchText:string)=>{
    dispatch(setRecruiterListState({
      ...RecruiterListState,
      pageIndex: 1,
      searchKeywords:searchText
    }))
    setIsDispatchedState(true);
  }

  // Handle sort change
  const _handleSortChange = (isAcendingSort:boolean, sortColumn: string) =>{
    dispatch(setRecruiterListState({
      ...RecruiterListState,
      pageIndex: 1,
      sortOrder: isAcendingSort ? SortType.Ascending : SortType.Descending,
      sortColumn: sortColumn
    }))
    setIsDispatchedState(true);
  }

  // Handle Recruiter Contract status changes

  const handleContractStatusChange = (flag:boolean) =>{
    dispatch(setRecruiterListState({
      ...RecruiterListState,
      pageIndex: 1,
      ShowRecruitersWithPendingContract: flag
    }))
    setIsDispatchedState(true);
  }

  //  Handle the Recruiter filter change

  const _handleOnStatusChange = (selectedStatuses :any)=>{
    dispatch(setRecruiterListState({
      ...RecruiterListState,
      pageIndex: 1,
      status:selectedStatuses
    }))
    setIsDispatchedState(true);
  }

  // Handle Fitler multi changes 
  // Location filter
  const _handleOnLocationChange = (selectedLocations:string[])=>{
    dispatch(setRecruiterListState({
      ...RecruiterListState,
      pageIndex: 1,
      filterByMasterData:{
        ...RecruiterListState.filterByMasterData,
        locationFilterInputs:selectedLocations
      }
    }))
    setIsDispatchedState(true);
  }

  // Industries Filter
  const _handleOnIndustryChange = (selectedIndustries: string[])=>{
    
    dispatch(setRecruiterListState({
      ...RecruiterListState,
      pageIndex: 1,
      filterByMasterData:{
        ...RecruiterListState.filterByMasterData,
        industryFilterInputs:selectedIndustries
      }
    }))
    setIsDispatchedState(true);
  }

  // Domain filter
  const _handleOnDomainChange = (selectedDomains: string[])=>{

    dispatch(setRecruiterListState({
      ...RecruiterListState,
      pageIndex: 1,
      filterByMasterData:{
        ...RecruiterListState.filterByMasterData,
        domainFilterInputs:selectedDomains
      }
    }))
    setIsDispatchedState(true);
  }

  //Skill filter
  const _handleOnSkillChange = (selectedSkills: string[])=>{
    dispatch(setRecruiterListState({
      ...RecruiterListState,
      pageIndex: 1,
      filterByMasterData:{
        ...RecruiterListState.filterByMasterData,
        skillsFilterInputs:selectedSkills
      }
    }))
    setIsDispatchedState(true);
  }

  const _handlePaginationIndex = (index:number) => {
    dispatch(
      setRecruiterListState({
        ...RecruiterListState,
        pageIndex: index,
      })
    );
    if(index === 1){
      setIsDispatchedState(true);
    }
    
  };

  const getCity = (description: any) => {
    let city = getAddressForAutoComplete(description).city;

    return city;
  };

  function handleGetRecruiters(response : AxiosResponse<any, any>) {

    setTotalRecruiterCount(response?.data?.totalRecords || 0);
    let responseData = response.data.entityList.map((item:any) => ({
      recruiterFullName: `${item.firstName ?? ""}  ${item.middleName ?? ""} ${item.lastName ?? ""
        }`,
      firstName: item.firstName,
      middleName: item.middleName,
      lastName: item.lastName,
      email: item.email,
      imageUrl: item.imageInfo?.imageUrl,
      primaryPhone: item.phoneNumbers?.primary ?? "",
      provider: item.company,
      location: `${item.address?.city ?? ""} ${ternary(
        item.address?.state,
        "" + ", " + item.address?.state,
        "N/A"
      )}`,
      feedback:
        item.feedbackNotes[item.feedbackNotes.length - 1]
          ?.feedbackDescription ?? "N/A",
      Status: item.status,
      id: item.id,
      totalRecords: response.data.totalRecords,
      createdDateEST:item.createdDateEST,
      createdDate:item.createdDate,
    }));
    setRecruiterDataList([...responseData]);
  }

  const _GetRecruiterList = ()=>{  
    setRecruiterDataList([]);  
    let selectedLocations: string[] = valueOrDefault(
      RecruiterListState.filterByMasterData?.locationFilterInputs?.map((item: any) =>{
        return getCity(item.description)
      }),
      []
    );

    let selectedIndutries: string[] = valueOrDefault(
      RecruiterListState.filterByMasterData?.industryFilterInputs?.map((item: Industry) => item.id),
      []
    );

    let selectedDomains: string[] = valueOrDefault(
      RecruiterListState.filterByMasterData?.domainFilterInputs?.map((item: any) => item.id),
      []
    );

    let selectedSkills: string[] = valueOrDefault(
      RecruiterListState.filterByMasterData?.skillsFilterInputs?.map((item: any) => item.id),
      []
    );
        
    setLoading(true);

    let requestPayload = {
      ...RecruiterListState,
      filterByMasterData:{
      ...RecruiterListState.filterByMasterData,
      locationFilterInputs:selectedLocations,
      industryFilterInputs: selectedIndutries,
      domainFilterInputs: selectedDomains,
      skillsFilterInputs: selectedSkills
      }
    }

    GetRecruiters(requestPayload)
      .then((response) => {
        if(response?.data?.message?.appStatusCode === "RC_RSF"){
          recruiterPayloadRef.current = requestPayload;
          handleGetRecruiters(response);
        }else{
          setTotalRecruiterCount(0);
          setRecruiterDataList([]);
        }
       
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false)
        setIsDispatchedState(false);
      });
  }


  return (
    <Grid container spacing={2}>
      {/* Row 1 */}
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <RecruiterListTableToolbar
              totalRecruiterCount={totalRecruiterCount}
              handleClearGlobalAll={_handleClearGlobalAll} 
              RecruiterListState={RecruiterListState}
              handleSearchbar={_handleSearchKeywords}
              handleOnStatusChange={_handleOnStatusChange}
              handleOnLocationChange={_handleOnLocationChange}
              handleOnIndustryChange={_handleOnIndustryChange}
              handleOnDomainChange = {_handleOnDomainChange}
              handleOnSkillChange ={_handleOnSkillChange}
              handleContractStatusChange={handleContractStatusChange}
              selectedRecruiterStatuses = {RecruiterListState?.status ?? []}
              selectedRecruiterLocation = {RecruiterListState?.filterByMasterData?.locationFilterInputs ?? []}
              selectedRecruiterIndustries= {RecruiterListState?.filterByMasterData?.industryFilterInputs ?? []}
              selectedRecruiterDomains = {RecruiterListState?.filterByMasterData?.domainFilterInputs ?? []}
              selectedRecruiterSkills = {RecruiterListState?.filterByMasterData?.skillsFilterInputs ?? []}
              
            />
          </Grid>
        </Grid>

        {/* Row 2 */}
        <Grid item xs={12}>
            <RecruiterListTable
              recruitersData={recruiterDataList}
              isLoading={loading}
              handleSortChange={_handleSortChange}
              totalRecordCount={totalRecruiterCount}
              handlePaginationIndex={_handlePaginationIndex}
              RecruiterListState={RecruiterListState}
            />
        </Grid>
        <Grid item xs={12}>
            <GotoTop />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RecruiterList;
