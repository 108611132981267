import { createSlice } from "@reduxjs/toolkit";
import { RecruiterListRequestPayload } from "../../models/Recruiter/RecruiterListModel";
import { SortType } from "../../models/app/AppModels";
import { AppPaginationPerPage } from "../../utils/AppConstant";

const initialState: RecruiterListRequestPayload = {
    pageIndex: 1,
    pageSize: AppPaginationPerPage,
    sortColumn: "createdDate",
    sortOrder: SortType.Descending,
    isSorted: true,
    totalRecord: 0,
    searchKeywords: "",
    ShowRecruitersWithPendingContract:false,
    status:[],
    filterByMasterData: {
      locationFilterInputs: [],
      domainFilterInputs: [],
      industryFilterInputs: [],
      skillsFilterInputs: [],
    }
  }
  
const RecruiterListStateSlice = createSlice({
  name: "RecruiterListStateSlice",
  initialState,
  reducers: {
    setRecruiterListState: (state, action: any) => {
      return action.payload;
    },
    resetRecruiterListState: () => {
      return initialState;
    },
  },
});

export const { setRecruiterListState, resetRecruiterListState } = RecruiterListStateSlice.actions;

export default RecruiterListStateSlice.reducer;
