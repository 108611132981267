export const StatusEnumList = {
  interview_schedule :{
    "id" :"31ce0b36-a11f-4b94-98c2-481bc84af498",
    "eventName" : "Interview Scheduled",
    "isPositiveStatus": true,
    "description": "",
    "matchingEventsIds" : [
      "31ce0b36-a11f-4b94-98c2-481bc84af498",
      "32592982-ac16-40e9-a34b-b9d42a907af6",
      "e750fe42-4acf-437c-9ade-89b319920116",
      "65adbba6-a385-45cd-b630-2d97a7b13908"
  ],
  "matchingEventsInterviewIds" : [
    "31ce0b36-a11f-4b94-98c2-481bc84af498"
  ],
  "matchingEventsNames" : [
    "31ce0b36-a11f-4b94-98c2-481bc84af498",
    "32592982-ac16-40e9-a34b-b9d42a907af6",
    "e750fe42-4acf-437c-9ade-89b319920116",
    "65adbba6-a385-45cd-b630-2d97a7b13908"
]
  },
  candidate_submitted: {
    "id": "0233cd95-7653-4bae-8ca1-8cb055681b65",
    "eventName": "Candidate Submitted",
    "isPositiveStatus": true,
    "description": "",
    "matchingEventsNames": [
      "Candidate Submitted",
      "Candidate RTR Completed",
      "Candidate Authorization Due",
      "Candidate RTR Declined",
      "Under Review",
      "Approved by AM",
      "Rejected by AM",
      "Candidate Shortlisted",
      "Candidate not Qualified",
      "Pending Client Review",
      "Interview Cancelled by Client",
      "Interview Rescheduled by Client",
      "Next Round of Interview",
      "Interview Feedback Due",
      "Candidate Selected in Interview",
      "Candidate Rejected by Client",
      "Candidate Rejected In Interview",
      "Candidate Hired",
      "Candidate Declined Offer",
      "Contract Active",
      "Start Sheet Initiated",
      "Waiting for Offer",
      "Waiting for Contract activation"
    ],
    "matchingEventsIds": [
      "0233cd95-7653-4bae-8ca1-8cb055681b65",
      "6fbc1a64-d64d-4afc-8d0b-c6dccac8d6f3",
      "9c8ae546-612d-4628-9836-02861ef7d5b6",
      "9ac7ce5a-a161-441a-8b4a-24f1fdc8f568",
      "25df8d7e-58b4-449c-aa3f-9cec8653985b",
      "97b763e8-3ce1-47a8-9f62-be9175b70f4d",
      "588f12f4-72e8-423c-8ddb-1e29c098a33c",
      "31ce0b36-a11f-4b94-98c2-481bc84af498",
      "94410598-9a79-4934-a732-667906239a5a",
      "29b61f9d-ced5-47c1-9bbf-09b7b523fc54",
      "32592982-ac16-40e9-a34b-b9d42a907af6",
      "e750fe42-4acf-437c-9ade-89b319920116",
      "65adbba6-a385-45cd-b630-2d97a7b13908",
      "ffbb21bb-d30a-4732-b81e-e3eb6a3f7a10",
      "74165ff8-d969-466d-a465-0f1b1d95aa2e",
      "87930fb5-a3b5-4b68-9354-cf0a713f5b97",
      "14842a65-f79f-42f1-b212-e66955c2fb23",
      "13d8310b-a152-41fe-bb84-2ce2716d662a",
      "b059545c-2ac5-4114-94f3-2791e0e7e278",
      "cb083aae-7ed7-4417-b044-92f4a88b975c",
      "c1e6bf81-f1e9-4bff-a2f2-fd838c3586a7",
      "64768219-524e-4e16-9f85-2dc51d41b03c",
      "8107511a-8f2b-48c1-92de-c37b06005cd7"
    ]
  },
  candidate_rtr_completed :{
    "id": "6fbc1a64-d64d-4afc-8d0b-c6dccac8d6f3",
    "eventName": "Candidate RTR Completed",
    "isPositiveStatus": true,
    "description": "",
    "matchingEventsNames": [
      "Under Review",
      "Candidate Authorized"
    ],
    "matchingEventsIds": [
      "25df8d7e-58b4-449c-aa3f-9cec8653985b",
      "5c3ae546-612d-4628-9836-02861ef7d5b6"
    ]
  },
  candidate_authorized : {
    "id": "5c3ae546-612d-4628-9836-02861ef7d5b6",
    "eventName": "Candidate Authorized",
    "isPositiveStatus": true,
    "description": ""
  },
  candidate_cuthorization_due : {
    "id": "9c8ae546-612d-4628-9836-02861ef7d5b6",
    "eventName": "Candidate Authorization Due",
    "isPositiveStatus": true,
    "description": ""
  },
  candidate_rtr_declined :{
    "id": "9ac7ce5a-a161-441a-8b4a-24f1fdc8f568",
    "eventName": "Candidate RTR Declined",
    "isPositiveStatus": false,
    "description": ""
  },
  under_review :{
    "id": "25df8d7e-58b4-449c-aa3f-9cec8653985b",
    "eventName": "Under Review",
    "isPositiveStatus": true,
    "description": "",
    "matchingEventsNames": [
      "Candidate RTR Completed"
    ],
    "matchingEventsIds": [
      "6fbc1a64-d64d-4afc-8d0b-c6dccac8d6f3"
    ]
  },
  approved_by_am : {
    "id": "97b763e8-3ce1-47a8-9f62-be9175b70f4d",
    "eventName": "Approved by AM",
    "isPositiveStatus": true,
    "description": "",
    "matchingEventsNames": [
      "Submited to Client"
    ]
  },
  submited_to_client :  {
    "id": "97b763e8-3ce1-47a8-9f62-be9175b70f4d",
    "eventName": "Submited to Client",
    "isPositiveStatus": true,
    "description": ""
  },
  rejected_by_am : {
    "id": "588f12f4-72e8-423c-8ddb-1e29c098a33c",
    "eventName": "Rejected by AM",
    "isPositiveStatus": false,
    "description": ""
  },
  candidate_shortlisted : {
    "id": "31ce0b36-a11f-4b94-98c2-481bc84af498",
    "eventName": "Candidate Shortlisted",
    "isPositiveStatus": true,
    "description": ""
  },
  candidate_not_qualified : {
    "id": "94410598-9a79-4934-a732-667906239a5a",
    "eventName": "Candidate not Qualified",
    "isPositiveStatus": false,
    "description": ""
  },
  pending_client_review : {
    "id": "29b61f9d-ced5-47c1-9bbf-09b7b523fc54",
    "eventName": "Pending Client Review",
    "isPositiveStatus": true,
    "description": ""
  },
  interview_cancelled_by_client : {
    "id": "32592982-ac16-40e9-a34b-b9d42a907af6",
    "eventName": "Interview Cancelled by Client",
    "isPositiveStatus": true,
    "description": ""
  },
  interview_rescheduled_by_client : {
    "id": "e750fe42-4acf-437c-9ade-89b319920116",
    "eventName": "Interview Rescheduled by Client",
    "isPositiveStatus": true,
    "description": ""
  },
  next_round_of_interview : {
    "id": "65adbba6-a385-45cd-b630-2d97a7b13908",
    "eventName": "Next Round of Interview",
    "isPositiveStatus": true,
    "description": ""
  },
  interview_feedback_due : {
    "id": "ffbb21bb-d30a-4732-b81e-e3eb6a3f7a10",
    "eventName": "Interview Feedback Due",
    "isPositiveStatus": true,
    "description": ""
  },
  candidate_selected_in_interview : {
    "id": "74165ff8-d969-466d-a465-0f1b1d95aa2e",
    "eventName": "Candidate Selected in Interview",
    "isPositiveStatus": true,
    "description": "",
    "matchingEventsNames": [
      "Waiting for Offer"
    ],
    "matchingEventsIds": [
      "64768219-524e-4e16-9f85-2dc51d41b03c"
    ]
  },
  candidate_rejected_by_client : {
    "id": "87930fb5-a3b5-4b68-9354-cf0a713f5b97",
    "eventName": "Candidate Rejected by Client",
    "isPositiveStatus": false,
    "description": ""
  },
  candidate_rejected_in_interview : {
    "id": "14842a65-f79f-42f1-b212-e66955c2fb23",
    "eventName": "Candidate Rejected In Interview",
    "isPositiveStatus": false,
    "description": ""
  },
  candidate_hired : {
    "id": "13d8310b-a152-41fe-bb84-2ce2716d662a",
    "eventName": "Candidate Hired",
    "isPositiveStatus": true,
    "description": "",
    "matchingEventsNames": [
      "Waiting for Contract activation"
    ],
    "matchingEventsIds": [
      "8107511a-8f2b-48c1-92de-c37b06005cd7"
    ],
    "matchingEventsOfferIds": [
      "13d8310b-a152-41fe-bb84-2ce2716d662a"
    ],
  },
  candidate_declined_offer : {
    "id": "b059545c-2ac5-4114-94f3-2791e0e7e278",
    "eventName": "Candidate Declined Offer",
    "isPositiveStatus": false,
    "description": ""
  },
  contract_active : {
    "id": "cb083aae-7ed7-4417-b044-92f4a88b975c",
    "eventName": "Contract Active",
    "isPositiveStatus": true,
    "description": ""
  },
  start_sheet_initiated : {
    "id": "c1e6bf81-f1e9-4bff-a2f2-fd838c3586a7",
    "eventName": "Start Sheet Initiated",
    "isPositiveStatus": true,
    "description": ""
  },
  waiting_for_offer : {
    "id": "64768219-524e-4e16-9f85-2dc51d41b03c",
    "eventName": "Waiting for Offer",
    "isPositiveStatus": true,
    "description": ""
  },
  waiting_for_contract_activation : {
    "id": "8107511a-8f2b-48c1-92de-c37b06005cd7",
    "eventName": "Waiting for Contract activation",
    "isPositiveStatus": true,
    "description": ""
  },
  submitted_to_am : {
    "id": "25df8d7e-58b4-449c-aa3f-9cec8653985b",
    "eventName": "Submitted to AM",
    "isPositiveStatus": true,
    "description": "",
    "matchingEventsNames": [
        "Candidate RTR Completed"
    ],
    "matchingEventsIds": [
        "6fbc1a64-d64d-4afc-8d0b-c6dccac8d6f3"
    ]
}
}