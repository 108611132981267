// Utility function to check if the client module is enabled
export function getIsClientModuleEnabled(): boolean {
    // Access the environment variable REACT_APP_IS_CLIENT_MODULE_ENABLED
    const isClientModuleEnabled = process.env.REACT_APP_IS_CLIENT_MODULE_ENABLED;
  
    // Check if the environment variable is not present or is set to 'false'
    if (!isClientModuleEnabled || isClientModuleEnabled === 'false') {
      return false;
    }
  
    // Return true if the environment variable is present and not 'false'
    return true;
  }

  // Utility function to check if the ScorecardFeatureActive module is enabled or not
export function getIsScorecardFeatureActive(): boolean {

  // Return true means scorecard feature is active otherwise hide the scorecard functionality
  return false;
}