import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import StatisticsCard from "./Cards/StatisticsCard";
import DashboardIcon1 from "./../../assets/images/dashboard-icon-1.svg";
import DashboardIcon3 from "./../../assets/images/dashboard-icon-3.svg";
import DashboardIcon4 from "./../../assets/images/dashboard-icon-4.svg";
import Domain from "./Cards/Domain";
import RecruitersLifecycle from "./Cards/RecruitersLifecycle";
import TopSubmissionsAdmin from "./Cards/TopSubmissionsAdmin";
import RecruiterOnboarded from "./Cards/RecruiterOnboarded";
import RelevantRecruiters from "./Cards/RelevantRecruiters";
import { ExpiringJob } from "../Common/ExpiringJob";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminGraphicalDashboard } from "../../features/dashboard/adminGraphicalDashboardSlice";
import AcceptanceRate from "./Cards/AcceptanceRate";
import { adminDashboardCount } from "../../features/dashboard/adminDashboardSlice";
import formatAmount from "../../utils/formatAmount";
import { useNavigate } from "react-router-dom";
import ActivityScore from "./Cards/ActivityScore";
import TopProfiles from "./Cards/TopProfiles";
import { UserAuth } from "../../models/interfaces";
import ScoreBySkills from "./Cards/ScoreBySkills";
import { StatusEnumList }  from "../Common/SubmissionStatusEnumList"
import { setRecruiterSubmissionState as setActiveRecruiterSubmissionState } from "../../features/recruiterSubmission/activeRecruiterSubmissionSlice";
import { getNevLink } from "../../utils/getFileFromUrl";
import { handleDurationFilter, setFilterType, setStatusIds } from "../../features/submission/submissionSlice";
import { resetAllJobFilter } from "../../features/Jobs/clientJobSlice";
import { setRecruiterListState } from "../../features/Recruiter/RecruiterListStateSlice";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));


export default function AdminDash() {
  const DashboardWidgets = true;  
  const navigate = useNavigate()
  const{data} = useSelector((state) => state.adminGraphicalData)
  const{dashboardCount} = useSelector((state) => state.adminDashboard)

  let activeSubmissionState =  useSelector((state) => state.activeRecruiterSubmissionState)

   //acces the recruiter redux state
   const RecruiterListState = useSelector((state)=>state.recruiterListState);

  const dispatch = useDispatch();
  useEffect(() => {
  dispatch(adminGraphicalDashboard())
  dispatch(adminDashboardCount())

 
  }, [])

  const handleCardClick = (statusIds, durationValue="0", durationType="All") => {
    dispatch(setActiveRecruiterSubmissionState({
      ...activeSubmissionState,
      pageIndex : 1,
      statusIds: statusIds,
      durationFilter: { duration: "0", durationType: "All" },
      candidateGuids: [],
      recruiterFilter: [],
      selectedRecruiter: undefined,
      searchKeywords: "",
      statusClearAll: true
    }));
    dispatch(
      handleDurationFilter({
        durationValue: durationValue,
        durationType: durationType,
      })
    );
    dispatch(setStatusIds({statusIds:statusIds, filterType :"filter"}))
    dispatch(setFilterType("filter"))
    navigate(getNevLink(`/submission/active`));
  }

  let graphicalData = data?.entity?.adminGraphicalDashboard;
  let adminDashboard = dashboardCount?.entity?.adminDashboard?.adminDashboardData?.cardCounts
  return (
    <div>
     
      <ExpiringJob type="Admin"/>
      
          
          <Grid >
            <Item elevation={0}>
             

            <Box sx={{ marginTop: "10px",paddingBottom: "30px" }}>
                <Grid
                  container
                  spacing={1}
                  sx={{ justifyContent: "left", textAlign: "left" }}
                  className="dashboard-sml-cards"
                >
                    <Grid item xs={12} sm={6} lg={3}>
                    <Item elevation={0} sx={{ textAlign: "left" }} style={{ cursor:"pointer"}} 
                      data-testid="ActiveRecruitersNav"
                      onClick={() => {
                        localStorage.removeItem("jobAdvanceSearchData");
                        navigate(getNevLink('/jobs/all'));
                        dispatch(resetAllJobFilter())
                      }}
                    >
                      <StatisticsCard
                        bg="bg2"
                        yourStatText="Active Jobs"
                        bigNo={formatAmount(adminDashboard?.activeJobs)}
                        sinceText="Today"
                        icon={DashboardIcon1}
                      />
                    </Item>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <Item elevation={0} sx={{ textAlign: "left" }} style={{ cursor:"pointer"}} 
                      data-testid="ActiveJobsNav"
                      onClick={() => {
                        navigate(getNevLink('/recruiters?status=Approved'));
                      }}
                    >
                      <StatisticsCard
                        bg="bg1"
                        yourStatText="Active Recruiters"
                        bigNo={formatAmount(adminDashboard?.activeRecruiters)}
                        sinceText={`Approved`}
                        icon={""}
                      />
                    </Item>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                  <Item elevation={0} sx={{ textAlign: "left" }} style={{ cursor:"pointer"}}
                    data-testid="interviewpending"
                    onClick={() => {
                      navigate(getNevLink('/recruiters?status=Pending'));
                    }}
                  > 
                      <StatisticsCard
                        bg="bg4"
                        yourStatText="Pending Recruiters"
                        bigNo={formatAmount(graphicalData?.agingRecruiterSignUpWidget?.recruitersUnderPendingStatus24Hrs)}
                        sinceText="All"
                        icon={DashboardIcon3}
                      />
                    </Item>
                  
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                  <Item elevation={0} sx={{ textAlign: "left" }} style={{ cursor:"pointer"}}
                    data-testid="Pendingcontracts"
                    onClick={() => {
                      dispatch(setRecruiterListState({
                        ...RecruiterListState,
                        pageIndex: 1,
                        status:[],
                        ShowRecruitersWithPendingContract:true
                        }))
                      navigate(getNevLink('/recruiters'));
                    }}
                  > 
                      <StatisticsCard
                         bg="bg3"
                        yourStatText="Pending Contracts"
                        bigNo={formatAmount(graphicalData?.agingRecruiterSignUpWidget?.approvedRecruitersPendingDigitalSignature24Hrs)}
                        sinceText="All"
                        icon={DashboardIcon3}
                      />
                    </Item>
                  </Grid>                
                  <Grid item xs={12} sm={6} lg={3}>
                  <Item elevation={0} sx={{ textAlign: "left" }} style={{ cursor:"pointer"}}
                    data-testid="TotalSubmissionsNav"
                    onClick={()=>{
                      handleCardClick([]);
                    }}
                  > 
                      <StatisticsCard
                        bg="bg3"
                        yourStatText="Total Submissions"
                        bigNo={formatAmount(adminDashboard?.totalCandidates)}
                        sinceText="Active"
                        icon={DashboardIcon3}
                      />
                    </Item>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                  <Item elevation={0} sx={{ textAlign: "left" }} style={{ cursor:"pointer"}}
                    data-testid="InProgressNav"
                    onClick={()=>{
                      handleCardClick([StatusEnumList?.contract_active?.id]);
                    }}
                  > 
                      <StatisticsCard
                        bg="bg4"
                        yourStatText="Contracts Active"
                        bigNo={formatAmount(adminDashboard?.contractsActive)}
                        sinceText="In - Progress"
                        icon={DashboardIcon4}
                      />
                    </Item>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                  <Item elevation={0} sx={{ textAlign: "left" }} style={{ cursor:"pointer"}}
                    data-testid="submittedAm"
                    onClick={()=>{
                      handleCardClick([StatusEnumList?.submitted_to_am?.id],"1","Days");
                    }}
                  > 
                      <StatisticsCard
                        bg="bg1"
                        yourStatText="Submitted to AM"
                        bigNo={formatAmount(graphicalData?.agingSubmissionWidget?.submittedToAm24Hrs)}
                        sinceText="24 Hours"
                        icon={DashboardIcon3}
                      />
                    </Item>
                  </Grid> 
                  <Grid item xs={12} sm={6} lg={3}>
                  <Item elevation={0} sx={{ textAlign: "left" }} style={{ cursor:"pointer"}}
                    data-testid="submittedAmclient"
                    onClick={()=>{
                      handleCardClick([StatusEnumList?.submited_to_client?.id],  "2","Days");
                    }}
                  > 
                      <StatisticsCard
                        bg="bg2"
                        yourStatText="Submitted to Client"
                        bigNo={formatAmount(graphicalData?.agingSubmissionWidget?.submittedToClient48Hrs)}
                        sinceText="48 Hours"
                        icon={DashboardIcon3}
                      />
                    </Item>
                  </Grid> 
                  <Grid item xs={12} sm={6} lg={6}>
                  <Item elevation={0} sx={{ textAlign: "left" }} style={{ cursor:"pointer"}}
                    data-testid="interviewscheduled"
                    onClick={()=>{
                      handleCardClick([StatusEnumList?.interview_schedule?.id], "2","Days");
                    }}
                  > 
                      <StatisticsCard
                        bg="bg3"
                        yourStatText="Interview Scheduled"
                        bigNo={(graphicalData?.agingSubmissionWidget?.interviewScheduledIn48Hrs)}
                        sinceText="48 Hours"
                        icon={DashboardIcon3}
                      />
                    </Item>
                  </Grid> 

                  <Grid item xs={12} sm={6} lg={6}>
                  <Item elevation={0} sx={{ textAlign: "left" }} style={{ cursor:"pointer"}}
                    data-testid="FeedbackPending"
                    onClick={()=>{
                      handleCardClick([StatusEnumList?.interview_feedback_due?.id], "2","Days");
                    }}
                  > 
                      <StatisticsCard
                        bg="bg1"
                        yourStatText="Interview Feedback Pending"
                        bigNo={(graphicalData?.agingSubmissionWidget?.interviewFeedbackPendingIn48Hrs)}
                        sinceText="48 Hours"
                        icon={DashboardIcon3}
                      />
                    </Item>
                  </Grid>                                    
                </Grid>
                <Grid container spacing={2} mt={0}>
                    {/* Total Recruiters */}
                  { DashboardWidgets === false &&  <Grid item xs={12} md={4}  sx={{ padding: 0 }}>
                    <Item
                      elevation={0}
                      sx={{ textAlign: "left", padding: 0, height: "240px" }}

                      className="dashboard-sml-card2 bg1"
                    >
                      <AcceptanceRate data={graphicalData?.acceptanceRate}  />
                    </Item>
                  </Grid>}
                    {/* Recruiters by Domain */}
                    { DashboardWidgets === false &&<Grid item xs={12} md={4}  sx={{ padding: 0 }}>
                    <Item
                      elevation={0}
                      sx={{ textAlign: "left", padding: 0, height: "240px" }}

                      className="dashboard-sml-card2 bg1"
                    >

                      <Domain data={graphicalData?.recruitersByDomain}/>
                    </Item>
                  </Grid>}
                       {/* Top Submissions */}
                  { DashboardWidgets === false && <Grid item xs={12} md={4}  sx={{ padding: 0 }}>
                    <Item
                      elevation={0}
                      sx={{ textAlign: "left", padding: 0, height: "240px" }}

                      className="dashboard-sml-card2 bg1"
                    >
                     {graphicalData?.topSubmissions!==undefined && <TopSubmissionsAdmin data={graphicalData?.topSubmissions}/> }
                    </Item>
                  </Grid>}
                   {/* Recruiters Lifecycle*/}
                  {DashboardWidgets === false &&  <Grid item xs={12} md={4}  sx={{ padding: 0 }}>
                    <Item
                      elevation={0}
                      sx={{ textAlign: "left", padding: 0, height: "320px" }}

                      className="dashboard-sml-card2 bg1"
                    >
                  {graphicalData?.recruiterLifecycle!==undefined &&<RecruitersLifecycle data={graphicalData?.recruiterLifecycle} /> }  

                      
                    </Item>
                  </Grid>}
                  {/* Recruiter On Boarded */}
                  {DashboardWidgets === false && <Grid item xs={12} md={4}  sx={{ padding: 0 }}>
                    <Item
                      elevation={0}
                      sx={{ textAlign: "left", padding: 0, height: "320px" }}

                      className="dashboard-sml-card2 bg1"
                    >
                     {<RecruiterOnboarded data={graphicalData?.recruiterOnboarded} />}
                    </Item>
                  </Grid>}
                  {/* Relevant Recruiters */}
                 {DashboardWidgets === false &&  <Grid item xs={12} md={4}  sx={{ padding: 0 }}>
                    <Item
                      elevation={0}
                      sx={{ textAlign: "left", padding: 0, height: "320px" }}

                      className="dashboard-sml-card2 bg1"
                    >
                    {graphicalData?.relevantRecruiters!==undefined &&  <RelevantRecruiters data={graphicalData?.relevantRecruiters}/>} 
                    </Item>
                  </Grid>}

                  
                  
                  {/* Top Recruiters */}
                { DashboardWidgets === false &&  <Grid item xs={12} md={4}  sx={{ padding: 0 }}>
                    <Item
                      elevation={0}
                      sx={{ textAlign: "left", padding: 0, height: "320px" }}

                      className="dashboard-sml-card2 bg1"
                    >
                      <TopProfiles data={graphicalData?.topRecruiters} userType={UserAuth.Admin} />
                    </Item>
                  </Grid>}
                  
                  {/* FulFilled by Skills */}
                { DashboardWidgets === false &&  <Grid item xs={12} md={4}  sx={{ padding: 0 }}>
                    <Item
                      elevation={0}
                      sx={{
                        textAlign: "left",
                      
                        height: "320px",
                      
                      }}
                      className="dashboard-sml-card2 bg1"
                    >
                      <ScoreBySkills data={graphicalData?.scoreBySkills} userType={UserAuth.Admin} />
                    </Item>
                  </Grid>}
                  
                  {/* Activity Score  */}
                  <Grid item xs={12} md={4}  sx={{ padding: 0 }}>
                    <Item
                      elevation={0}
                      sx={{ textAlign: "left", padding: 0, height: "320px" }}

                      className="dashboard-sml-card2 bg1"
                    >
                      <ActivityScore data={graphicalData?.activityScore}/>
                    </Item>
                  </Grid>

                </Grid>
              </Box>
             
            </Item>
          </Grid>
       
    </div>
  );
}
